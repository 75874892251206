import React from 'react'
import "./Footer.css"
function Footer() {
  return (
    <div className='FooterAcp' style={{marginTop:"20px"}}>
        Copyright © todos los derechos reservados ACP Gestión y Marketing 2023
    </div>
  )
}

export default Footer