import React, { useState } from "react";
import "./Testimonials.css";
import comilla from "../../Assets/comilla.png";
import eco from "../../Assets/testimonials/ecocuyum.jpg"
import oliver from "../../Assets/testimonials/oliver.jpg"
import cayetano from "../../Assets/cayetano.png"
import rustico from "../../Assets/testimonials/rusticos_mendoza.jpg"
import reborn from "../../Assets/testimonials/reborn.png"

function Testimonials() {
  const [Testimonials] = useState([
    {
      src: <img src={oliver} width="120px" height="50px" />,
      title: "Florencia Manzanel, Grupo J B Justo",
      desc: "Tuvimos una excelente experiencia trabajando con la consultora ACP, hicieron un análisis completo de nuestra cafeteria, nos hicieron una propuesta de organización y obtuvimos excelentes resultados con ello. Estamos totalmente satisfechos con su trabajo.",
      active: false,
    },
    {
      src: <img src={rustico} width="120px" height="50px" />,
      title: "Martin Aguero. Rusticos Mendoza",
      desc: "Me ayudaron mucho a re organizar mi empresa desde cero, muy exhaustivo el diagnostico, lo cual me permitió enforcarme en las areas que estaban sin organización y dirección.",
      active: true,
    },
    {
      src: <img src={cayetano} width="120px" height="50px" />,
      title: "Ivan Yoma. Cayetano",
      desc: "Con ACP hemos encontrado un aliado estratégico en el desarrollo continuo de nuestra imagen corporativa, marketing digital y la creación de nuestra nueva página web. Su enfoque y dedicación han sido clave para impulsar nuestro negocio al siguiente nivel",
      active: false,
    },
    {
      src: <img src={eco} width="120px" height="50px" />,
      title: "Armando Parlanti. Eco Cuyum",
      desc: "Actualmente estamos planificando nuestro desarrollo de imagen corporativa y desarrollo publicitario con ACP y ya estamos implementando la venta online, gracias a la colaboración de la consultora.",
      active: true,
    },
    {
      src: <img src={reborn} width="120px" height="50px" />,
      title: "Fourcade Heytel. Reborn",
      desc: "Su equipo nos proporciona estrategias clave y el apoyo necesario para cumplir nuestros objetivos. Son un socio confiable que aporta experiencia, creatividad y compromiso en cada paso del camino.",
      active: true,
    },
  ]);
  return (
    <section className="Testimonials-experience" id="testimonials">
      <h1 className="TestimonialsTitle">CLIENTES</h1>
      <div className="containerTest">
        {Testimonials.map((Testimonial, i) => (
          <div key={i} className="cardContainerTest">
            <div className="imgSpaceTest">
              <div className="SvgContainerTest">{Testimonial.src}</div>
            </div>
            <p className="TestimonialDesciption">{Testimonial.desc}</p>
            <div className="titleCardTest">{Testimonial.title}</div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Testimonials;
