import React from "react";
import "./HomeText.css";
import Typewriter from "typewriter-effect";


function HomeText() {
  return (

<div class="marquee">
  <div class="track">
    <div class="content">&nbsp; Publicidad,
        Ventas online,
       Redes sociales,
       Desarrollo web,
      Creación de marcas,
       Gestión interna,
       Publicidad,
        Ventas online,
       Redes sociales,
       Desarrollo web,
      Creación de marcas,
       Gestión interna,
       Publicidad,
        Ventas online,
       Redes sociales,
       Desarrollo web,
      Creación de marcas,
       Gestión interna,
       Publicidad,
        Ventas online,
       Redes sociales,
       Desarrollo web,
      Creación de marcas,
       Gestión interna,</div>
  </div>
</div>
     
  );
}

export default HomeText;
