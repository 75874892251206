import React, { useState, createRef } from "react";
import "./Home.css";
import logo from "../../Assets/presentar.png"; 
import pict from "../../Assets/program.jpeg";
import pict1 from "../../Assets/reuniones.jpg";
import line from "../../Assets/line.png";
import  WhatsApp from "../../Assets/WhatsApp.svg.webp";
import { DefaultPlayer as Video } from "react-html5video";
import videoTest from '../../Assets/video.mp4'
import "react-html5video/dist/styles.css";
import HomeText from "./HomeText";

function Home() {
  const [model, setModel] = useState(false);
  const videoProperties = [
    {
      id: 1,
      title: "",
      src: videoTest,
      credit: "Testimonio",
      ph: "https://scontent-mia3-2.xx.fbcdn.net/v/t1.6435-9/81491659_10218964411035744_441341880035180544_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=730e14&_nc_ohc=xW1njeYPoNgAX_e3kFX&_nc_ht=scontent-mia3-2.xx&oh=00_AfDWmsafNxPtIqduepqe3xB-lTR53WgpFTkJpYEBaQR1XA&oe=648B345F",
    }]
  return (
    <div id="home">
     
      <div className="AboutBody"> 
        {/* <div className="AboutContainer"> */}
        {/* <HomeText /> */}
          {/* <div className="StyledHeading">C O N S U L T O R A</div> */}
          {/* <img className="fotoo" src={line} alt="linea" /> */}
          {/* <div className="TextSection">
            <img className="Photo2" src={logo} width="400px" />
           
          </div>
        </div> */}
        {/* <div className="textPosition">
        <HomeText />
        </div> */}
        {/* <div className="position">
              <button className="btninfo">Más info aquí</button>
            </div> */}
        <div className="AboutContainer">
          <div className="videSite">
        {videoProperties.map((item, index) => {
            let divRef = createRef(null);
            const openModel = () => {
              divRef.current.classList.remove("video");
              divRef.current.classList.add("model");
              setModel(true);
            };
            const closeModel = () => {
              divRef.current.classList.add("video");
              divRef.current.classList.remove("model");
              setModel(false);
            };
            return (
              <div ref={divRef} className="video" key={index}>
                {/* {model && (
                  <button
                    className="model-close-btn"
                    style={{ marginTop: "150px" }}
                    onClick={() => closeModel()}
                  >
                    X
                  </button>
                )} */}
                <div className="video-container" onClick={() => openModel()}>
                  {/* <h4 style={{ color: "black", textAlign: "center" }}>
                    {item.title}{" "}
                  </h4> */}
                  <Video
                    style={{ with: "10%" }}
                    autoPlay={model}
                    controls={[
                      "PlayPause",
                      "Seek",
                      "Time",
                      "Volume",
                      "Fullscreen",
                    ]}
                    poster={item.ph} //aca una foto de cada testimonio
                  >
                    <source
                      style={{ width: "70%", height: "70%" }}
                      src={item.src}
                      type="video/webm"
                    />
                  </Video>
                </div>
              </div>
            );
          })}
          </div>
          
        </div>
      </div>
      <a href="https://api.whatsapp.com/send?phone=5492616075153">
        {/* <AiFillGithub className='gitHub'/> */}
        <img src={WhatsApp} className="btnFlotante" />
      </a>
      <HomeText />
    </div>
  );
}

export default Home;
